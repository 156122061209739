import axios from "axios";
import { BASE_API_URL } from "../constants/constants";

var accessToken = localStorage.getItem("access_token");

export const LoginAdmin = async (dataObj) => {
  try {
    const response = await axios.post(`${BASE_API_URL}/login`, dataObj);
    return response.data; // You may return a success message or relevant data.
  } catch (error) {
    throw error;
  }
};
