import { Avatar, List } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";

const App = ({ reportedUsers }) => {
  const navigation = useNavigate();

  const goToUsers = () => {
    navigation("/members");
  };

  return (
    <List
      onClick={() => {}}
      itemLayout="horizontal"
      dataSource={reportedUsers}
      style={{
        maxHeight: 250,
        background: "white",
        overflowY: "scroll",
        padding: 10,
        borderRadius: 8,
        cursor: "pointer",
      }}
      header={
        <div>
          <strong>Reported Users</strong>
        </div>
      }
      renderItem={(obj, index) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar
                size={36}
                src={obj?.owner?.profilePic ? obj?.owner?.profilePic : null}
                icon={!obj?.owner?.profilePic ? <UserOutlined /> : null}
              />
            }
            title={obj?.owner?.name}
            description={
              <>
                <div>{obj?.owner?.email}</div>
                <div>Report Count: {obj?.reportCount}</div>
              </>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default App;
