import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const LineChartComponent = ({ data, title }) => {
  return (
    <div
      style={{
        borderRadius: "0.75rem",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        border: "1px solid #E4E7EB",
        padding: "1rem",
      }}
    >
      <h2
        style={{
          color: "black",
          marginBottom: "30px",
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        {title}
      </h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data} margin={{ top: 10, left: 0, bottom: 0 }}>
          <XAxis dataKey="categoryName" />
          <YAxis tickLine={false} orientation="left" axisLine={false} />
          <Tooltip cursor={{ fill: "#d9ffe8" }} />
          <Bar
            barSize={100}
            dataKey="videoCount"
            fill="rgba(39, 253, 150, 1)"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartComponent;
