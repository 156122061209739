import React, { useState, useEffect } from "react";
import { Line } from "@ant-design/plots";
import { Column } from "@ant-design/plots";
import { getAllUser } from "../../services/users";
import { getAllVideos } from "../../services/videos";
import "./chart.scss";

const DemoLine = ({ allUsers, allInfluencers }) => {
  const calculateUsersPerMonth = (data) => {
    const userCounts = {};

    data.forEach((user) => {
      const createdAtMonth = user.createdAt.substring(0, 7); // Extract the year and month part
      userCounts[createdAtMonth] = (userCounts[createdAtMonth] || 0) + 1;
    });

    const result = Object.keys(userCounts).map((label) => ({
      type: label,
      sales: userCounts[label],
    }));

    return result;
  };

  const userData = calculateUsersPerMonth(allUsers);

  const influencerData = calculateUsersPerMonth(allInfluencers);

  const config = {
    data: userData,
    xField: "type",
    yField: "sales",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Month", // Rename the 'type' to 'Month'
      },
      sales: {
        alias: "Number of Users", // Rename the 'sales' to 'Number of Users'
      },
    },
  };

  const configInfluencer = {
    data: influencerData,
    xField: "type",
    yField: "sales",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Month", // Rename the 'type' to 'Month'
      },
      sales: {
        alias: "Number of Influencers", // Rename the 'sales' to 'Number of Users'
      },
    },
    color: "rgba(39, 253, 150, 0.8)",
  };

  return (
    <div
      className="responsive-container"
      style={{ display: "flex", gap: "20px" }}
    >
      <div className="chart-container">
        <h2
          style={{
            color: "#6395fa",
            marginBottom: "30px",
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          Users
        </h2>
        <Column {...config} />
      </div>

      <div className="chart-container">
        <h2
          style={{
            color: "#5bfdaf",
            marginBottom: "30px",
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          INFLUENCERS
        </h2>
        <Column {...configInfluencer} />
      </div>
    </div>
  );
};

export default DemoLine;

////////////////////////////////////////////
