import { Avatar, Modal, Space, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import React, { useState } from "react";

export const ThumbnailPreviewer = ({
  previewUrl,
  setPreviewUrl,
  handleDeleteImage,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleCancel = () => {
    setPreviewUrl(null);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Delete Alert!"
        visible={isDeleteModalOpen}
        onOk={() => {
          setPreviewUrl(null);
          setIsDeleteModalOpen(false);
          handleDeleteImage();
        }}
        okText="Confirm"
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
      >
        <p>Are you sure to Delete...</p>
      </Modal>

      <Modal
        visible={previewUrl ? true : false}
        footer={null}
        onCancel={handleCancel}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Avatar size={350} src={previewUrl} />
          <Space style={{ position: "absolute", top: 10, left: 2 }}>
            <Button
              type="primary"
              danger
              onClick={() => setIsDeleteModalOpen(true)}
              style={{ marginRight: 10 }}
            >
              <DeleteOutlined />
              Delete
            </Button>
          </Space>
        </div>
      </Modal>
    </>
  );
};
