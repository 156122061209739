import React, { useContext, useState, useEffect } from "react";
import { Pie } from "@ant-design/plots";

const App = ({ totalUsers, totalInfluencers }) => {
  // Icons for users and videos
  const userIcon = "Users";
  const videoIcon = "Influencers";

  // Define data with icons
  const data = [
    { type: userIcon, value: totalUsers }, // Use the length of users as the value
    { type: videoIcon, value: totalInfluencers }, // Use the length of videos as the value
  ];

  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  return (
    <>
      <Pie
        style={{
          background: "white",
          marginBottom: 20,
          height: 240,
          borderRadius: 10,
        }}
        {...config}
      />
    </>
  );
};

export default App;
