// import React, { useState } from 'react';
// import { AiFillSetting } from 'react-icons/ai';
// import { MdOutlineNotificationsNone } from 'react-icons/md';
// import { FaSignOutAlt } from 'react-icons/fa'; // Import the logout icon
// import { Modal, Button, Switch, Select } from 'antd';
// import './style.css';
// import Swal from 'sweetalert2';
// import { notifySuccess, notifyInfo } from "../../components/Toast"; // Make sure to import the relevant notification functions
// import { useNavigate } from 'react-router-dom'; // Import useNavigate

// const { Option } = Select;

// const Header = () => {
//   const [isSettingsVisible, setIsSettingsVisible] = useState(false);
//   const [darkTheme, setDarkTheme] = useState(false);
//   const [language, setLanguage] = useState('English');
//   const [quality, setQuality] = useState('1080p');
//   const [autoPlay, setAutoPlay] = useState(true);
//   const [subtitles, setSubtitles] = useState(true);
//   const navigate = useNavigate(); // Define navigate here

//   const goToLogout = () => {
//     localStorage.clear();
//     navigate('/');
//   };

//   const logout = () => {
//     Swal.fire({
//       title: 'Are you sure you want to logout?',
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, logout',
//       cancelButtonText: 'Cancel',
//     }).then((result) => {
//       if (result.isConfirmed) {
//         localStorage.clear();
//         notifySuccess('You have been logged out successfully');
//         navigate("/");
//       } else if (result.dismiss === Swal.DismissReason.cancel) {
//         notifyInfo('Logout canceled');
//       }
//     });
//   };

//   const showModal = () => {
//     setIsSettingsVisible(true);
//   };

//   const handleOk = () => {
//     setIsSettingsVisible(false);
//     // Save settings here
//   };

//   const handleCancel = () => {
//     setIsSettingsVisible(false);
//   };

//   return (
//     <div>
//       <div className='header'>
//         <MdOutlineNotificationsNone />
//         <AiFillSetting onClick={showModal} />
//         <FaSignOutAlt onClick={logout} style={{ cursor: 'pointer' }} />
//       </div>
//       <Modal
//         title="Settings"
//         visible={isSettingsVisible}
//         onOk={handleOk}
//         onCancel={handleCancel}
//         footer={[
//           <Button key="back" onClick={handleCancel}>
//             Cancel
//           </Button>,
//           <Button key="submit" type="primary" onClick={handleOk}>
//             Save
//           </Button>,
//         ]}
//         centered // Center the modal
//       >
//         <div className="setting">
//           <label>Dark Theme</label>
//           <Switch checked={darkTheme} onChange={() => setDarkTheme(!darkTheme)} />
//         </div>
//         <div className="setting">
//           <label>Language</label>
//           <Select value={language} onChange={(value) => setLanguage(value)}>
//             <Option value="English">English</Option>
//             <Option value="Spanish">Spanish</Option>
//             <Option value="French">French</Option>
//           </Select>
//         </div>
//         <div className="setting">
//           <label>Video Quality</label>
//           <Select value={quality} onChange={(value) => setQuality(value)}>
//             <Option value="1080p">1080p</Option>
//             <Option value="720p">720p</Option>
//             <Option value="480p">480p</Option>
//           </Select>
//         </div>
//         <div className="setting">
//           <label>AutoPlay</label>
//           <Switch checked={autoPlay} onChange={() => setAutoPlay(!autoPlay)} />
//         </div>
//         <div className="setting">
//           <label>Subtitles</label>
//           <Switch checked={subtitles} onChange={() => setSubtitles(!subtitles)} />
//         </div>
//       </Modal>
//     </div>
//   );
// };

// export default Header;

import React, { useContext, useState } from "react";
import { AiFillSetting } from "react-icons/ai";
import { MdMessage } from "react-icons/md";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { FaSignOutAlt } from "react-icons/fa"; // Import the logout icon
import { Modal, Button, Switch, Select } from "antd";
import "./style.css";
import {
  notifySuccess,
  notifyInfo,
  notifyConfirm,
} from "../../components/Toast"; // Import toast notification functions
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ChatContext from "../../services/chat-service";
import Auth from "../../services/auth-service";
import fitnContext from "../../context/fitnContext";

const { Option } = Select;

const Header = () => {
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [darkTheme, setDarkTheme] = useState(false);
  const [language, setLanguage] = useState("English");
  const [quality, setQuality] = useState("1080p");
  const [autoPlay, setAutoPlay] = useState(true);
  const [subtitles, setSubtitles] = useState(true);
  const navigate = useNavigate();

  const goToLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        notifySuccess("You have been logged out successfully");

        Auth.logout()
          .then(() => {
            console.log("logout sucess");
          })
          .catch((error) => {
            console.error("logout error", error);
          });

        navigate("/");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // notifyInfo("Logout canceled");
      }
    });
  };

  const showModal = () => {
    setIsSettingsVisible(true);
  };

  const handleOk = () => {
    setIsSettingsVisible(false);
    // Save settings here
  };

  const handleCancel = () => {
    setIsSettingsVisible(false);
  };

  return (
    <div>
      <div className="header">
        {/* <MdOutlineNotificationsNone /> */}
        <FaSignOutAlt onClick={logout} style={{ cursor: "pointer" }} />
      </div>
      <Modal
        title="Settings"
        visible={isSettingsVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Save
          </Button>,
        ]}
        centered
      >
        {/* <div className="setting">
          <label>Dark Theme</label>
          <Switch checked={darkTheme} onChange={() => setDarkTheme(!darkTheme)} />
        </div>
        <div className="setting">
          <label>Language</label>
          <Select value={language} onChange={(value) => setLanguage(value)}>
            <Option value="English">English</Option>
            <Option value="Spanish">Spanish</Option>
            <Option value="French">French</Option>
          </Select>
        </div>
        <div className="setting">
          <label>Video Quality</label>
          <Select value={quality} onChange={(value) => setQuality(value)}>
            <Option value="1080p">1080p</Option>
            <Option value="720p">720p</Option>
            <Option value="480p">480p</Option>
          </Select>
        </div>
        <div className="setting">
          <label>AutoPlay</label>
          <Switch checked={autoPlay} onChange={() => setAutoPlay(!autoPlay)} />
        </div>
        <div className="setting">
          <label>Subtitles</label>
          <Switch checked={subtitles} onChange={() => setSubtitles(!subtitles)} />
        </div> */}
      </Modal>
    </div>
  );
};

export default Header;
