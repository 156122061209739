import React, { useState, useEffect } from "react";
import { Table, Modal, Dropdown, Menu, Pagination, Input, Radio } from "antd";
import {
  getAllPlaylist,
  updatePlayList,
  deletePlaylist,
} from "../../services/playlists";
import ReactPlayer from "react-player";

import {
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  DownOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { notifySuccess } from "../../components/Toast";

import "./style.scss";
import { ThumbnailPreviewer } from "../../components/ThumbnailPreviewer";

const PlayListsComp = ({ userId }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [playListDetail, setPlayListDetail] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [playlistData, setPlaylistData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [playlisId, setPlaylistId] = useState(null);

  const [pageNumber, setPageNumber] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);

  const [userName, setUserName] = useState("");
  const [FeatureValue, setFeatureValue] = useState(null);

  const onChangePagination = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const onChangeFeature = (e) => {
    setFeatureValue(e.target.value);
  };

  const handleChangeName = (e) => {
    setUserName(e.target.value);
  };

  const fetchplaylistData = (pgnmbr) => {
    setIsLoading(true);

    getAllPlaylist(pgnmbr, userId)
      .then((resp) => {
        if (resp.type === "success") {
          setPlaylistData(resp?.data?.playlists);
          setTotalUsers(resp?.data?.totalPlaylists);
          setIsLoading(false);
        }

        if (resp.type === "bad") {
          setPlaylistData([]);

          setIsLoading(false);
        }
      })
      .catch((err) => {
        setPlaylistData([]);
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchplaylistData(pageNumber);
  }, [pageNumber, userId]);

  const handleDelete = () => {
    deletePlaylist(deleteId)
      .then((resp) => {
        if (resp.type === "success") {
          fetchplaylistData(pageNumber);
          setIsDeleteModalOpen(false);
          notifySuccess(
            "PlayList has been successfully deleted. ",
            resp.message,
            "success"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openVideoModal = (video) => {
    setSelectedVideo(video);
    setModalVisible(true);
    console.log(selectedVideo);
  };

  const handleEdit = () => {
    let body = { isPrivate: FeatureValue, name: userName };
    updatePlayList(playlisId, body)
      .then((resp) => {
        if (resp.type === "success") {
          setIsDeleteModalOpen(false);

          fetchplaylistData(pageNumber);
          notifySuccess(resp.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setUserName(null);

    setFeatureValue(null);
    setPlaylistId(null);
    setIsEditModalOpen(false);
    setPlayListDetail({});
  };

  const handleDeleteImage = () => {
    let body = {
      imageUrl:
        "https://fitn-bucket.s3.eu-west-2.amazonaws.com/1718204937278.jpeg",
    };
    updatePlayList(playlisId, body)
      .then((resp) => {
        if (resp.type === "success") {
          setIsDeleteModalOpen(false);
          fetchplaylistData(pageNumber);
          notifySuccess("Image Replaced");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setUserName(null);

    setFeatureValue(null);
    setPlaylistId(null);
    setIsEditModalOpen(false);
    setPlayListDetail({});
  };

  const columns = [
    {
      title: "ThumbNail",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (text, record) => (
        <img
          onClick={() => {
            setPreviewUrl(record?.imageUrl);
            setPlaylistId(record._id);
          }}
          src={record.imageUrl}
          alt="thumbnail"
          style={{ width: "120px", maxHeight: "20px", cursor: "pointer" }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "isPrivate",
      key: "isPrivate",
      render: (text, record) => (
        <span>{record.isPrivate ? "Private" : "Public"}</span>
      ),
    },
    {
      title: "Total Videos",
      key: "openVideoDropdown",
      dataIndex: "videos",
      render: (text, record) => {
        return <span style={{ color: "black" }}>{record?.videos?.length}</span>;
        // const menu = (
        //   <Menu>
        //     {record?.videos?.length > 0 ? (
        //       record.videos.map((item, index) => (
        //         <Menu.Item key={index} onClick={() => openVideoModal(item)}>
        //           {item}
        //         </Menu.Item>
        //       ))
        //     ) : (
        //       <Menu.Item>No Videos available</Menu.Item>
        //     )}
        //   </Menu>
        // );

        // return (
        //   <Dropdown overlay={menu}>
        //     <a
        //       className="ant-dropdown-link"
        //       onClick={(e) => e.preventDefault()}
        //     >
        //       Play Videos <DownOutlined />
        //     </a>
        //   </Dropdown>
        // );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPlayListDetail(record);
              setIsDetailsModalOpen(true);
            }}
          >
            <EyeOutlined />
          </span>
          <span
            style={{ marginLeft: 16, cursor: "pointer" }}
            onClick={() => {
              setPlayListDetail(record);
              setIsEditModalOpen(true);
              setPlaylistId(record._id);

              setUserName(record?.name);
              setFeatureValue(record?.isPrivate);
            }}
          >
            <EditOutlined />
          </span>
          <span
            style={{ marginLeft: 16, cursor: "pointer" }}
            onClick={() => {
              setIsDeleteModalOpen(true);
              setDeleteId(record._id);
            }}
          >
            <DeleteOutlined />
          </span>
        </>
      ),
    },
  ];

  const scrollConfig = {
    y: "60vh", // Set the maximum height in pixels or other CSS units
  };

  return (
    <div className="userContainer">
      <div className="tableInfo">
        <h1>PLAYLISTS</h1>
      </div>

      {previewUrl && (
        <ThumbnailPreviewer
          previewUrl={previewUrl}
          setPreviewUrl={setPreviewUrl}
          handleDeleteImage={handleDeleteImage}
        />
      )}

      <div className="tableData">
        {playlistData && (
          <Table
            dataSource={playlistData}
            columns={columns}
            loading={isLoading ? true : false}
            scroll={scrollConfig}
          />
        )}
      </div>

      <div className="paginationComp">
        <Pagination
          defaultCurrent={1}
          total={totalUsers}
          onChange={onChangePagination}
        />
      </div>

      <Modal
        title="Delete Alert!"
        visible={isDeleteModalOpen}
        onOk={handleDelete}
        okText="Confirm"
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setDeleteId(null);
        }}
      >
        <p>Are you sure to Delete...</p>
      </Modal>

      <Modal
        title="Edit PlayList!"
        visible={isEditModalOpen}
        onOk={handleEdit}
        okText="Update"
        onCancel={() => {
          setPlaylistId(null);

          setIsEditModalOpen(false);
          setPlayListDetail({});
          setUserName(null);
          setFeatureValue(null);
        }}
      >
        <div className="formContainer">
          <div className="inputContainer">
            <div className="spanData">
              <span>Name : </span>
            </div>
            <div className="inputdata">
              <Input
                autocomplete="off"
                value={userName}
                onChange={handleChangeName}
              />
            </div>
          </div>

          <div className="inputContainer">
            <div className="spanData">
              <span>IsPrivate : </span>
            </div>
            <div className="inputdata">
              <Radio.Group onChange={onChangeFeature} value={FeatureValue}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </div>
          </div>

          {/* <div className="inputContainer">
              <div className="spanData">
                <span>Role : </span>
              </div>
              <div className="inputdata">
                <Radio.Group onChange={onChangeRole} value={RoleValue}>
                  <Radio value="user">User</Radio>
                  <Radio value="influencer">Influencer</Radio>
                </Radio.Group>
              </div>
            </div> */}
        </div>
      </Modal>

      <Modal
        title="PlayList Details!"
        visible={isDetailsModalOpen}
        okText="OK"
        onOk={() => {
          setIsDetailsModalOpen(false);
          setPlayListDetail({});
        }}
        onCancel={() => {
          setIsDetailsModalOpen(false);
          setPlayListDetail({});
        }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span className="title" style={{ fontWeight: "bold" }}>
              ThumbNall
            </span>
            <img
              src={playListDetail.imageUrl}
              alt="thumbnail"
              style={{
                width: "200px",
                height: "200px",
                borderRadius: "50%",
              }}
            />
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Name :
            </span>
            <span className="detail">{playListDetail.name}</span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              createdAt :
            </span>
            <span className="detail">{playListDetail.createdAt}</span>
          </div>
        </div>
      </Modal>
      <Modal
        title={selectedVideo ? selectedVideo.name : ""}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        {selectedVideo && (
          <div style={{ position: "relative" }}>
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              {/* 
      <ReactPlayer
                url={selectedVideo.url}
                controls
                width="100%"
                height="100%"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              /> */}

              <video
                src={selectedVideo.name}
                controls
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <h2>{selectedVideo.name}</h2>
              <p>Video description or other information</p>
              <div style={{ display: "flex", alignItems: "center" }}></div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default PlayListsComp;
