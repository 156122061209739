import axios from "axios";
import { BASE_API_URL } from "../constants/constants";

export const createVideo = async (videoData) => {
  try {
    // Assuming you have a valid endpoint for creating videos, replace 'createVideoEndpoint' with your actual API endpoint.
    const response = await axios.post(
      `${BASE_API_URL}/upload/video`,
      videoData
    );
    return response.data; // You may return the newly created video or a success message.
  } catch (error) {
    throw error;
  }
};

export const getAllPlaylist = (pgnumber, userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const perPage = 10; // Set your desired perPage value
      const pageNo = pgnumber;

      var config = {
        method: "get",
        url: `${BASE_API_URL}/playLists/?perPage=${perPage}&pageNo=${pageNo}&userId=${userId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          //console.log("Sign Up response Error:", error);
          reject(error);
        });
    } catch (error) {
      //console.log(`Exception - Service - Signup ${error}`);
      reject(error);
    }
  });
};

export const updatePlayList = (PlayListId, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const accessToken = localStorage.getItem("access_token");

      var config = {
        method: "patch",
        url: `${BASE_API_URL}/playLists/${PlayListId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: body,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch (error) {
      //console.log(`Exception - Service - GetLoggedUser ${error}`);
      reject(error);
    }
  });
};

export const deletePlaylist = async (playListId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const accessToken = localStorage.getItem("access_token");

      var config = {
        method: "delete",
        url: `${BASE_API_URL}/playLists/${playListId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch (error) {
      //console.log(`Exception - Service - GetLoggedUser ${error}`);
      reject(error);
    }
  });
};
