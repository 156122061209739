import React, { useEffect } from "react";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import FitnStateContext from "./context/FitnStateContext";

import { Layout, Menu, Card } from "antd";
import SideBar from "./Pages/Sidebar";
import Header from "./Pages/Header";
import Sider from "antd/es/layout/Sider";
import UserTable from "./Pages/User";
import Category from "./Pages/Category";
import "./App.css";
import VideoTable from "./Pages/Videos";
import PlayListsComp from "./Pages/Playlist";
import DashboardLayout from "./Pages/Dashboard/DashboardLayout";
import PrivateRoute from "./Pages/PrivateRoute";
import { ToastContainer } from "react-toastify";
import ChatScreen from "./Pages/Chat/ChatScreen";

import Auth from "./services/auth-service";
import ReportedVideos from "./Pages/ReportedVideos";
import Feedbacks from "./Pages/Feedbacks";

const App = () => {
  useEffect(() => {
    // Auth.init()
    //   .then(() => {
    //     console.log("connected");
    //     Auth.login("sarfraz31105@gmail.com", "txend1122")
    //       .then((resp) => {
    //         console.log("resp", resp);
    //       })
    //       .catch((err) => {
    //         console.log("login error", err);
    //       });
    //   })
    //   .catch((err) => {
    //     console.log("connection error:", err);
    //   });
  }, []);

  return (
    <FitnStateContext>
      <ToastContainer />
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />}></Route>

          {/* <Route
            path="/ChatHome"
            element={!localStorage.token ? <Login /> : <ChatHome />}
          ></Route> */}

          <Route
            exact
            path="/dashboard"
            element={
              <PrivateRoute>
                <DashboardLayout>
                  <Dashboard />
                </DashboardLayout>
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/members"
            element={
              <PrivateRoute>
                <DashboardLayout>
                  <UserTable />
                </DashboardLayout>
              </PrivateRoute>
            }
          ></Route>

          <Route
            exact
            path="/ChatScreen"
            element={
              <PrivateRoute>
                <DashboardLayout>
                  <ChatScreen />
                </DashboardLayout>
              </PrivateRoute>
            }
          ></Route>

          <Route
            exact
            path="/category"
            element={
              <DashboardLayout>
                <Category />
              </DashboardLayout>
            }
          ></Route>
          <Route
            exact
            path="/feedbacks"
            element={
              <DashboardLayout>
                <Feedbacks />
              </DashboardLayout>
            }
          ></Route>
          <Route
            exact
            path="/reels"
            element={
              <PrivateRoute>
                <DashboardLayout>
                  <VideoTable />
                </DashboardLayout>
              </PrivateRoute>
            }
          ></Route>

          <Route
            exact
            path="/reported_videos"
            element={
              <PrivateRoute>
                <DashboardLayout>
                  <ReportedVideos />
                </DashboardLayout>
              </PrivateRoute>
            }
          ></Route>

          <Route
            exact
            path="/playlist"
            element={
              <PrivateRoute>
                <DashboardLayout>
                  <PlayListsComp />
                </DashboardLayout>
              </PrivateRoute>
            }
          ></Route>
        </Routes>
      </Router>
    </FitnStateContext>
  );
};

export default App;
