import { toast } from 'react-toastify';

export const notifyError = (message) =>
  toast.error(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const notifySuccess = (message) =>
  toast.success(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
export const notifyWarn = (message) =>
  toast.warning(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
export const notifyInfo = (message) =>
  toast.info(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  // export const notifyConfirm = (message, onConfirm) => {
  //   toast(message, {
  //     position: "top-right",
  //     autoClose: false,
  //     closeOnClick: false,
  //     closeButton: false,
  //     hideProgressBar: true,
  //     progress: undefined,
  //     render: ({ closeToast }) => (
  //       <div>
  //         <div>{message}</div>
  //         <button
  //           onClick={() => {
  //             closeToast();
  //             onConfirm(true); // "Ok" button clicked
  //           }}
  //         >
  //           Ok
  //         </button>
  //         <button
  //           onClick={() => {
  //             closeToast();
  //             onConfirm(false); // "Cancel" button clicked
  //           }}
  //         >
  //           Cancel
  //         </button>
  //       </div>
  //     ),
  //   });
  // };
  


  export const notifyConfirm = (message, onConfirm) => {
    toast(message, {
      position: "top-right",
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      progress: undefined,
      render: ({ closeToast })=> (
        <div>
          <div>{message}</div>
          <button
            onClick={() => {
              closeToast();
              onConfirm(true); // "Ok" button clicked
            }}
          >
            Ok
          </button>
          <button
            onClick={() => {
              closeToast();
              onConfirm(false); // "Cancel" button clicked
            }}
          >
            Cancel
          </button>
        </div>
      ),
    });
  };
  