import React, { useState, useEffect } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import {
  FaUserPlus,
  FaUserMinus,
  FaChartLine,
  FaPercent,
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Function to combine and adjust data
const combineData = (
  newUserData,
  leftUserData,
  startDate,
  endDate,
  isMonthly,
  isYearly
) => {
  const dataMap = new Map();

  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    const formattedDate = d.toISOString().split("T")[0];
    dataMap.set(formattedDate, {
      date: formattedDate,
      newUser: 0,
      leftUser: 0,
    });
  }

  newUserData.forEach((item) => {
    if (dataMap.has(item.date)) {
      dataMap.get(item.date).newUser = item.newUser;
    }
  });

  leftUserData.forEach((item) => {
    if (dataMap.has(item.date)) {
      dataMap.get(item.date).leftUser = -item.leftUser;
    }
  });

  if (isMonthly) {
    const monthlyData = new Map();
    dataMap.forEach((value, key) => {
      const month = key.substring(0, 7); // YYYY-MM
      if (!monthlyData.has(month)) {
        monthlyData.set(month, { date: month, newUser: 0, leftUser: 0 });
      }
      monthlyData.get(month).newUser += value.newUser;
      monthlyData.get(month).leftUser += value.leftUser;
    });
    return Array.from(monthlyData.values());
  }

  if (isYearly) {
    const yearlyData = new Map();
    dataMap.forEach((value, key) => {
      const year = key.substring(0, 4); // YYYY
      if (!yearlyData.has(year)) {
        yearlyData.set(year, { date: year, newUser: 0, leftUser: 0 });
      }
      yearlyData.get(year).newUser += value.newUser;
      yearlyData.get(year).leftUser += value.leftUser;
    });
    return Array.from(yearlyData.values());
  }

  return Array.from(dataMap.values());
};

const calculateMetrics = (newUserData, leftUserData) => {
  const totalNewUsers = newUserData.reduce(
    (sum, item) => sum + item.newUser,
    0
  );
  const totalLeftUsers = leftUserData.reduce(
    (sum, item) => sum + item.leftUser,
    0
  );
  const netFollowerGrowth = totalNewUsers - totalLeftUsers;

  const percentageChange = (current, previous) => {
    if (previous === 0) return "N/A";
    return (((current - previous) / previous) * 100).toFixed(2);
  };

  return {
    totalNewUsers,
    totalLeftUsers,
    netFollowerGrowth,
    newUsersChange: percentageChange(
      totalNewUsers,
      totalNewUsers - netFollowerGrowth
    ),
    leftUsersChange: percentageChange(
      totalLeftUsers,
      totalLeftUsers + netFollowerGrowth
    ),
  };
};

const LineChartComponentUsers = ({ newUserData, leftUserData, title }) => {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  const [activeButton, setActiveButton] = useState("Current Month");

  useEffect(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    setDateRange([firstDayOfMonth, today]);
  }, []);

  const getDateRange = (label) => {
    const today = new Date();
    switch (label) {
      case "Current Month":
        return [new Date(today.getFullYear(), today.getMonth(), 1), today];
      case "Last Month":
        const lastMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
        return [lastMonth, lastMonthEnd];
      case "6 Months":
        return [new Date(today.setMonth(today.getMonth() - 6)), new Date()];
      case "1 Year":
        return [
          new Date(today.setFullYear(today.getFullYear() - 1)),
          new Date(),
        ];
      case "From 2020":
        return [new Date(2020, 0, 1), today];
      default:
        return [new Date(today.getFullYear(), today.getMonth(), 1), today];
    }
  };

  const handleButtonClick = (range, label) => {
    setDateRange(range);
    setActiveButton(label);
  };

  const combinedData = combineData(
    newUserData,
    leftUserData,
    startDate,
    endDate,
    activeButton === "6 Months" || activeButton === "1 Year",
    activeButton === "From 2020"
  );

  const filteredData = combinedData.filter(
    (entry) =>
      new Date(entry.date) >= startDate && new Date(entry.date) <= endDate
  );

  const metrics = calculateMetrics(newUserData, leftUserData);

  console.log("metrics", metrics);

  const buttonStyle = {
    padding: "0.5rem 1rem",
    margin: "0 0.5rem",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#4CAF50",
    color: "white",
    cursor: "pointer",
    transition: "background-color 0.3s",
  };

  const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#00330d",
  };

  return (
    <div
      style={{
        borderRadius: "1rem",
        boxShadow: "0 4px 6px blue",
        border: "1px solid #d1d5db",
        padding: "1rem",
      }}
    >
      <h2
        style={{
          color: "black",
          marginBottom: "30px",
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        {title}
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <button
          onClick={() =>
            handleButtonClick(getDateRange("Current Month"), "Current Month")
          }
          style={
            activeButton === "Current Month" ? activeButtonStyle : buttonStyle
          }
        >
          Current Month
        </button>
        <button
          onClick={() =>
            handleButtonClick(getDateRange("Last Month"), "Last Month")
          }
          style={
            activeButton === "Last Month" ? activeButtonStyle : buttonStyle
          }
        >
          Last Month
        </button>
        <button
          onClick={() =>
            handleButtonClick(getDateRange("6 Months"), "6 Months")
          }
          style={activeButton === "6 Months" ? activeButtonStyle : buttonStyle}
        >
          6 Months
        </button>
        <button
          onClick={() => handleButtonClick(getDateRange("1 Year"), "1 Year")}
          style={activeButton === "1 Year" ? activeButtonStyle : buttonStyle}
        >
          1 Year
        </button>
        <button
          onClick={() =>
            handleButtonClick(getDateRange("From 2020"), "From 2020")
          }
          style={activeButton === "From 2020" ? activeButtonStyle : buttonStyle}
        >
          From 2020
        </button>
        <div style={{ display: "flex", alignItems: "center" }}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setDateRange([date, endDate])}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
            dateFormat="yyyy/MM/dd"
          />
          <span style={{ margin: "0 10px" }}>to</span>
          <DatePicker
            selected={endDate}
            onChange={(date) => setDateRange([startDate, date])}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            dateFormat="yyyy/MM/dd"
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart data={filteredData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="newUser"
            stroke="#8884d8"
            fill="#8884d8"
            name="New Users"
          />
          <Area
            type="monotone"
            dataKey="leftUser"
            stroke="#82ca9d"
            fill="#82ca9d"
            name="Left Users"
          />
        </AreaChart>
      </ResponsiveContainer>
      <div style={{ marginTop: "20px" }}>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            backgroundColor: "white",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2",
                  color: "#333",
                }}
              >
                Users Metrics
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2",
                  color: "#333",
                }}
              >
                Totals
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2",
                  color: "#333",
                }}
              >
                Change{" "}
                <FaPercent
                  style={{ color: "#333", width: "10px", height: "10px" }}
                />
              </th>
            </tr>
          </thead>
          <tbody style={{ color: "rgb(102, 102, 102)" }}>
            <tr>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                <FaUserPlus
                  style={{
                    color: "#4CAF50",
                    width: "18px",
                    height: "18px",
                    marginRight: "10px",
                  }}
                />
                New Users
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  color: "rgb(102, 102, 102)",
                }}
              >
                {metrics.totalNewUsers}
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                {metrics.newUsersChange}{" "}
                <FaPercent
                  style={{ color: "#333", width: "10px", height: "10px" }}
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                <FaUserMinus
                  style={{
                    color: "#F44336",
                    width: "18px",
                    height: "18px",
                    marginRight: "10px",
                  }}
                />
                Left Users{" "}
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                {metrics.totalLeftUsers}
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                {metrics.leftUsersChange}{" "}
                <FaPercent
                  style={{ color: "#333", width: "10px", height: "10px" }}
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                <FaChartLine
                  style={{
                    color: "#2196F3",
                    width: "18px",
                    height: "18px",
                    marginRight: "10px",
                  }}
                />
                Net Growth{" "}
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                {metrics.netFollowerGrowth}
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                N/A
                <FaPercent
                  style={{ color: "#333", width: "10px", height: "10px" }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LineChartComponentUsers;
