import React from "react";
import { Layout } from "antd";
import SideBar from "../Sidebar";
import Header from "../Header";
import "./style.css";

const { Sider, Content } = Layout;

function Dashboard({ children }) {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        style={{ maxHeight: "100vh", background: "#121212", width: "250px" }}
      >
        <div className="logo" />
        <SideBar />
      </Sider>
      <Layout
        style={{ background: "#262626", color: "white", minHeight: "100vh" }}
        className="site-layout"
      >
        <Header
          className="site-header"
          style={{ padding: 0, height: "10vh" }}
        />
        {/* - IMPORT HERE - */}
        <div
          style={{ minHeight: "90vh", height: "100%" }}
          className="mainContainer"
        >
          {children}
        </div>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
