import axios from "axios";
import { BASE_API_URL } from "../constants/constants";

export const getAllReportedVideos = (pgnmbr, obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { isFeatured, isPrivate, name } = obj;

      const accessToken = localStorage.getItem("access_token");
      const perPage = 10; // Set your desired perPage value
      const pageNo = pgnmbr;

      var config = {
        method: "get",
        url: `${BASE_API_URL}/video_report/?perPage=${perPage}&pageNo=${pageNo}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          //console.log("Sign Up response Error:", error);
          reject(error);
        });
    } catch (error) {
      //console.log(`Exception - Service - Signup ${error}`);
      reject(error);
    }
  });
};

export const updateVideo = (videoId, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const accessToken = localStorage.getItem("access_token");

      var config = {
        method: "patch",
        url: `${BASE_API_URL}/videos/${videoId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: body,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch (error) {
      //console.log(`Exception - Service - GetLoggedUser ${error}`);
      reject(error);
    }
  });
};

export const deleteVideo = async (videoId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const accessToken = localStorage.getItem("access_token");

      var config = {
        method: "delete",
        url: `${BASE_API_URL}/videos/${videoId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch (error) {
      //console.log(`Exception - Service - GetLoggedUser ${error}`);
      reject(error);
    }
  });
};
