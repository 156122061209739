import React, { useState } from "react";
import { Form, Input, Button, Typography, Row, Col, Space } from "antd";
import "./Login.scss";
import { notifyError, notifySuccess } from "../../components/Toast/index";
import { useNavigate } from "react-router-dom";
import { LoginAdmin } from "../../services/Admin";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Auth from "../../services/auth-service";
import { Spin } from "antd";
const { Title } = Typography;

const Login = () => {
  const navigate = useNavigate();

  const [adminData, setAdminData] = useState({ email: "", password: "" });

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const loginFun = (values) => {
    setLoading(true);
    LoginAdmin(values)
      .then((resp) => {
        if (resp.status === "success") {
          let access_token = resp.data.access_token;
          Auth.init()
            .then(() => {
              Auth.login("admin@fitn.com", "fitn1122")
                .then((resp) => {
                  localStorage.setItem("access_token", access_token);
                  navigate("/dashboard");
                  // Display a toast message for successful login
                  notifySuccess("Login successful!");
                })
                .catch((err) => {
                  console.log("login error", err);
                });
            })
            .catch((err) => {
              console.log("connection error:", err);
            });
        } else {
          notifyError("Email Or Password Does Not Match!");
        }
      })
      .catch((err) => {
        notifyError("Email Or Password Does Not Match!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // use toast
  //           notifySuccess('Status Updated.');

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setAdminData((val) => ({ ...val, [name]: value })); // Remove the unnecessary array
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="login-form">
      <h1 style={{ margin: 0, fontSize: 52 }}> F I T N </h1>
      <h3 style={{ margin: 0, marginBottom: 20 }}>Welcome to FITN Community</h3>
      <div className="inputFlex">
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          onFinish={loginFun}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "email required!",
              },
            ]}
          >
            <Input
              autocomplete="off"
              type="email"
              value={adminData.email}
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "password required!",
              },
            ]}
          >
            <Input.Password
              autocomplete="off"
              value={adminData.password}
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              {loading ? (
                <Spin />
              ) : (
                <Button type="primary" htmlType="submit">
                  LogIn
                </Button>
              )}
            </Form.Item>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
