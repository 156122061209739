import axios from "axios";
import { BASE_API_URL } from "../constants/constants";

export const getDashboardData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const accessToken = localStorage.getItem("access_token");

      var config = {
        method: "get",
        url: `${BASE_API_URL}/dashboardData`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          //console.log("Sign Up response Error:", error);
          reject(error);
        });
    } catch (error) {
      //console.log(`Exception - Service - Signup ${error}`);
      reject(error);
    }
  });
};
